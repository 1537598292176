<template>
  <div style="height: calc(100vh - 130px); position:relative;">
    <mx-preloader :loader-status="preloader"/>
    <v-container fluid>
      <v-row>
        <v-col md="3" lg="2">
          <v-btn v-if="mxware.can('document-create')" :to="{name: 'document.create'}" color="primary white--text" class="rounded-0 mb-6 " x-large block elevation="1">
            {{ $t('document.buttons.document_create') }}
          </v-btn>
          <ul class="mx-group-btn">
            <li class="mx-group-btn__item" @click="check_btn_group(1)" :class="{'active': bt_goup === 1}">
              <span>Kategorien</span>
            </li>
            <li class="mx-group-btn__item" @click="check_btn_group(2)" :class="{'active': bt_goup === 2}">
              <span>Role</span>
            </li>
          </ul>

          <v-card v-show="bt_goup === 1" class="mx-auto" max-width="400">
            <v-list class="pa-0">
              <v-list-item-group v-model="category_id" mandatory>
                <v-list-item class="py-1" v-for="category in categories" :key="category.id" :disabled="category.disabled">
                  <v-list-item-content>
                    <v-list-item-title>
                     <div class="d-flex align-center">
                       <v-icon size="24" color="darken-4" class="mr-2 ml-2">mdi-folder-outline</v-icon>
                       <span>{{ category.name }}</span>
                     </div>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>

<!--          <h3 style="margin: 16px 0;">{{ $t('document.role.title') }}</h3>-->
          <v-card v-show="bt_goup === 2">
            <ul class="role-list">
              <li class="role-list-item" :class="{'active': role_active === role.id}" @click="check_role_active(role.id)" v-for="role in roles" :key="role.id">
                <v-icon size="24" color="darken-4" class="mr-2 ml-2">mdi-folder-outline</v-icon>
                <span>{{role.display_name}}</span>
              </li>
            </ul>
          </v-card>

        </v-col>
        <v-col md="9" lg="10" >
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label=" $t('document.table.search')"
              hide-details
              solo
              class="mb-6 elevation"
              height="53"
          ></v-text-field>
          <v-data-table :headers="headers" :items="getDocuments" :items-per-page="12" :search="search">
            <template v-slot:item.name="{ item }">
              <a target="_blank" :href="options.document_url + item.path">{{ item.name }}</a>
            </template>
            <template v-slot:item.file_type="{ item }">
              <small>{{ getFileType(item.file_type) }}</small>
            </template>
            <template v-slot:item.category_id="{ item }">
             {{ getCategory(item.category_id) }}
            </template>
            <template v-slot:item.for="{ item }">
              {{ getFor(item.for) }}
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn v-if="mxware.can('document-update')" text elevation="0" small :to="{name: 'document.edit', params: {id: item.id}}">
                <v-icon size="16" color="blue-grey darken-4">mdi-pencil-outline</v-icon>
              </v-btn>
              <v-btn v-if="mxware.can('document-delete')" text elevation="0" small @click="dialogOpen(item)">
                <v-icon size="16" color="red darken-4">mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="500">
        <v-card class="pa-4">
          <v-card-title class="headline mb-9">
            <small style="width: 100%; text-align: center; font-size: 14px; color: rgba(0, 0, 0, 0.6)">{{ selected_document.name }}</small>
            <h4 style="text-align: center; width: 100%;">
              {{ $t('document.titles.document_delete') }}
            </h4>
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-btn color="red" dark @click="documentDelete">
              {{ $t('document.buttons.delete') }}
            </v-btn>
            <v-btn color="grey" dark @click="dialogClose">
              {{ $t('document.buttons.abort') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Documents",
  data() {
    return {
      preloader: true,
      dialog: false,
      bt_goup: 1,
      role_active: 1,
      category_id: 0,
      search: '',
      headers:[
        { text: this.$t('document.table.name'), value: 'name' },
        { text: this.$t('document.table.type'), value: 'file_type' },
        { text: this.$t('document.table.category'), value: 'category_id' },
        { text: this.$t('document.table.for'), value: 'for' },
        { text: this.$t('document.table.create_at'), value: 'created_at' },
        { text: this.$t('document.table.update_at'), value: 'updated_at' },
        { text: '', value: 'action',sortable: false },
      ],
      categories:[],
      roles:[],
      documents: [],
      selected_document: {},
    }
  },
 created () {
    this.getCategories()
  },
  computed: {
    wHeight() {
      return window.innerHeight
    },
    getDocuments() {
      if (this.category_id === 0 && this.bt_goup === 1) {
        return this.documents
      }

      if (this.bt_goup === 2) {
        return this.documents.filter(document => {
          return document['roles'].includes(this.role_active)
        })
      }

      return this.documents.filter( document => {
        return document.category_id === this.category_id + 1
      })
    },
  },
  methods: {
    check_role_active(num) {
      this.role_active = num
    },
    check_btn_group(num) {
      this.bt_goup = num
      // if (num === 1) {
      //   this.role_active = null
      // }
    },
    dialogOpen(document) {
      this.selected_document = document
      this.dialog = true
    },
    dialogClose() {
      this.selected_document = {}
      this.dialog = false
    },
    documentDelete() {
      this.$store.dispatch('documentDelete', this.selected_document.id).then(() => {
        this.documents = this.$store.getters.documents
        this.dialogClose()
      })
    },
    getAllDocuments() {
      this.$store.dispatch('allDocuments').then(() => {
        this.documents = this.$store.getters.documents
        this.categories.map(category => {
          category['disabled'] = this.documents.findIndex(document => document.category_id === category.id) === -1 && category.id !== 1
          return category
        })

        setTimeout(() => {this.preloader = false}, 300)
      })
    },
   async getCategories() {
      await this.$store.dispatch('documentCategories').then(() => {
        this.categories = this.$store.getters.document_categories
        this.getAllDocuments()
      })
     await this.$store.dispatch('rolesEmployeeLaratrust').then(() => {
       this.roles = this.$store.getters.roles_employee_laratrust.data
       this.getAllDocuments()
     })
    },
    getCategory(category_id) {
      return this.categories.find(category => category.id === category_id).name
    },
    getFileType(type) {
      return type.toUpperCase()
    },
    getFor(fr) {
      return this.$t(`document.for.${fr}`)
    }
  }
}
</script>


<style lang="scss">
.role-list {
  list-style: none;
  padding: 0 !important;
  margin: 0;
  cursor: pointer;

  &-item {
    padding: 16px 16px;

    &.active {
      background: #e5e5e5 ;
    }
  }
}

.mx-group-btn {
  list-style: none;
  padding: 0 !important;
  margin: 0 0 16px 0;
  display: flex;

  &__item {
    background: #e5e5e5;
    width: 100%;
    padding: 6px 16px;
    cursor: pointer;

    &.active {
      background: #fc8b5d ;
    }
  }
}
</style>